import Text from '@ingka/text'
import { space100 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { CSATMetrics } from 'lib/types/coworkerData.types'

import { colourGreyNeutral4 } from 'styles/tokens/insikt.tokens'

import MainMetric from './components/MainMetric.component'
import AptitudeGraph from 'components/composites/Graphs/aptitudeGraph.component'

import SubPanelLayout from './SubPanel.layout'

interface Props {
  data: CSATMetrics
}

const CsatSubPanel = ({ data }: Props) => {
  return (
    <SubPanelLayout
      leftPanel={<CsatLeftPanel data={data} />}
      rightPanel={<CsatRightPanel data={data} />}
    />
  )
}

const CsatLeftPanel = ({ data }: { data: CSATMetrics }) => {
  return (
    <Flex direction={'column'} gap={space100} w={'100%'} justify={'space-evenly'}>
      <Text headingSize="m">{'CSAT'}</Text>
      <MainMetric
        value={data.csatR30.coworkerCsat}
        avg={data.csatR30.avgCoworkerCsat}
        top={data.csatR30.maxCoworkerCsat}
        color={'#005980'}
        unit={'%'}
      />

      <Text bodySize="s" style={{ fontWeight: 'bold' }}>
        {'CSAT is divded into:'}
      </Text>
      <Flex justify={'start'} gap={16}>
        <Flex direction={'row'} p={8} gap={16} align={'center'} w={'100%'}>
          <div style={{ backgroundColor: '#0096D2', height: '100%', width: 8 }} />
          <Flex direction={'column'}>
            <Text headingSize="s">{data.csatR30.manner}%</Text>
            <Text bodySize="s">{'Manner'}</Text>
          </Flex>
        </Flex>
        <Flex direction={'row'} p={8} gap={16} align={'center'} w={'100%'}>
          <div style={{ backgroundColor: '#8CDDFF', height: '100%', width: 8 }} />
          <Flex direction={'column'}>
            <Text headingSize="s">{data.csatR30.knowledge}%</Text>
            <Text bodySize="s">{'Knowledge'}</Text>
          </Flex>
        </Flex>
        <Flex direction={'row'} p={8} gap={16} align={'center'} w={'100%'}>
          <div style={{ backgroundColor: '#A3ACB0', height: '100%', width: 8 }} />
          <Flex direction={'column'}>
            <Text headingSize="s">{data.csatR30.solution}%</Text>
            <Text bodySize="s">{'Solution'}</Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex gap={16}>
        <Flex
          py={12}
          px={16}
          w={'100%'}
          style={{
            borderRadius: 4,
            borderWidth: 1,
            borderStyle: 'dashed',
            borderColor: colourGreyNeutral4,
          }}
        >
          <Text bodySize="s" style={{ fontWeight: 'bold' }}>
            {`${data.csatR30.surveyResponses} reponses`}
          </Text>
        </Flex>
        <Flex
          py={12}
          px={16}
          w={'100%'}
          style={{
            borderRadius: 4,
            borderWidth: 1,
            borderStyle: 'dashed',
            borderColor: colourGreyNeutral4,
          }}
        >
          <Text bodySize="s" style={{ fontWeight: 'bold' }}>
            {`${data.csatR30.responseRate}% reponse rate`}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

const CsatRightPanel = ({ data }: { data: CSATMetrics }) => {
  const { t } = useTranslation()

  return (
    <Flex direction={'column'}>
      <Text style={{ marginBottom: space100 }}>{t('features.1-efficiency.aptitude-subtitle')}</Text>
      <Flex w={'100%'} h={300}>
        <AptitudeGraph data={data.csatR8weeks} />
      </Flex>
    </Flex>
  )
}

export default CsatSubPanel
