export type CoworkerDataDocument = {
  legacyId: string
  coworkerData: CoworkerData
}

export type CoworkerData = {
  contactExperience: number
  countryCode: string
  endDateR30: string
  startDateR30: string
  teamLeaderLegacyId: string
  team: string
  csat: CSATMetrics
  skillData: SkillData[]
}

export type CSATMetrics = {
  csatR8weeks: Rolling8WeeksCSAT[]
  csatR30: Rolling30CSAT
}

export type Rolling8WeeksCSAT = {
  aptitude: number
  manner: number
  coworkerCsat: number
  knowledge: number
  solution: number
  salesWeek: string
  surveyResponses: number
}

export type Rolling30CSAT = {
  aptitude: number
  manner: number
  coworkerCsat: number
  knowledge: number
  solution: number
  surveyResponses: number
  responseRate: number
  enoughVolume: boolean
  avgAptitude: number
  avgManner: number
  avgKnowledge: number
  avgSolution: number
  avgCoworkerCsat: number
  maxAptitude: number
  maxManner: number
  maxKnowledge: number
  maxSolution: number
  maxCoworkerCsat: number
}

export type SkillData = {
  skill: string
  skillExperience: number
  department: Department
  enoughVolumeR30: boolean
  volumeR30: number
  aht: AverageHandleTime
  repeatContact: RepeatContact
  resolutionRecommendation: ResolutionRecommendation | null
}

export enum Department {
  Resolution = 'Resolution',
  Sales = 'Sales',
}

export type AverageHandleTime = {
  ahtLast30IxnR90days: AHTLast30IxnR90days[]
  ahtR30: Rolling30AHT
}

export type AHTLast30IxnR90days = {
  acwR30: number
  ahtR30: number
  dateLoc: string
  holdR30: number
  talkR30: number
}

export type Rolling30AHT = {
  aht: string
  potentialAht: string
  avgAht: string
  minAht: string
  enoughVolume: boolean
  impact: number
  impactRate: number
  avgTalkTime: number
  avgHoldTime: number
  avgAcwTime: number
  benchmarkAvgAcwTime: number
  benchmarkAvgHoldTime: number
  benchmarkAvgTalkTime: number
}

export type RepeatContact = {
  repeatR30: Rolling30Repeat
  repeatLast30IxnR90days: RepeatLast30IxnR90days[]
}

export type RepeatLast30IxnR90days = {
  repeatShare: number
  dateLoc: string
}

export type Rolling30Repeat = {
  enoughVolume: boolean
  avgRepeatShare: number | null
  minRepeatShare: number
  impact: number
  impactRate: number
  repeatShare: number
  potentialRepeatShare: number
}

export type ResolutionRecommendation = {
  recommendation: string
  recommendationContact: string
  strength: string
}
