import Text from '@ingka/text'
import { Flex } from '@mantine/core'

interface Props {
  value: number | string
  description: string
  color?: string
}

const SubMetric = ({ value, description, color }: Props) => {
  return (
    <Flex direction={'row'} p={8} gap={16} align={'center'}>
      <div style={{ backgroundColor: color ? color : '#A3ACB0', height: 50, width: 8 }} />
      <Flex direction={'column'}>
        <Text headingSize="xs">{value}</Text>
        <Text bodySize="s">{description}</Text>
      </Flex>
    </Flex>
  )
}

export default SubMetric
