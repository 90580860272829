import SSRIcon from '@ingka/ssr-icon'
import warningTriangle from '@ingka/ssr-icon/paths/warning-triangle'
import Text from '@ingka/text'
import { space25, space100 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { AverageHandleTime, SkillData } from 'lib/types/coworkerData.types'
import { displayTime } from 'lib/utils/displayTime.utils'

import { colourBrandYellow } from 'styles/tokens/oldSkapa.tokens'

import MainMetric from './components/MainMetric.component'
import SubMetric from './components/SubMetric.component'
import { AhtLineGraph } from 'components/composites/Graphs/ahtGraph.component'

import SubPanelLayout from './SubPanel.layout'

interface Props {
  data: SkillData
}

const AhtSubPanel = ({ data }: Props) => {
  const { t } = useTranslation()
  if (!data.aht) {
    return (
      <SubPanelLayout
        leftPanel={
          <Text bodySize="s" style={{ textAlign: 'center' }}>
            <SSRIcon paths={warningTriangle} style={{ marginRight: space25 }} />
            {t('No AHT data available')}
          </Text>
        }
        rightPanel={
          <Text bodySize="s" style={{ textAlign: 'center' }}>
            <SSRIcon paths={warningTriangle} style={{ marginRight: space25 }} />
            {t('No AHT data available')}
          </Text>
        }
      />
    )
  }

  return (
    <SubPanelLayout
      leftPanel={<AhtLeftPanel data={data} />}
      rightPanel={<AhtRightPanel data={data.aht} />}
    />
  )
}

const AhtLeftPanel = ({ data }: { data: SkillData }) => {
  const { t } = useTranslation()
  return (
    <Flex direction={'column'} gap={space100} w={'100%'} justify={'space-evenly'}>
      <Flex direction={'row'} justify={'space-between'} align={'center'}>
        <Text bodySize="s">{'Last 30 days'}</Text>
        {!data.aht.ahtR30.enoughVolume && (
          <Text bodySize="s" style={{ textAlign: 'center' }}>
            <SSRIcon paths={warningTriangle} style={{ marginRight: space25 }} />
            {t('features.1-efficiency.low-data-volume')}
          </Text>
        )}
      </Flex>

      <Flex direction={'row'} justify={'space-between'} align={'center'}>
        <Text
          headingSize="m"
          style={{
            width: '100%',
          }}
        >
          {'AHT'}
        </Text>

        <Text
          headingSize="s"
          style={{
            backgroundColor: colourBrandYellow,
            padding: space25,
            borderRadius: '5px',
            whiteSpace: 'nowrap',
            opacity: data.resolutionRecommendation?.recommendation === 'AHT' ? 1 : 0,
          }}
        >
          {t('features.1-efficiency.highest-potential')}
        </Text>
      </Flex>

      <MainMetric
        value={data.aht.ahtR30.aht}
        avg={data.aht.ahtR30.avgAht}
        top={data.aht.ahtR30.minAht}
        unit=" min."
        color="#008ca4e2"
      />

      <Text bodySize="s" style={{ fontWeight: 'bold' }}>
        {'AHT consists of: '}
      </Text>

      <Flex direction={'row'} justify={'space-between'} align={'center'}>
        <SubMetric
          value={displayTime(data.aht.ahtR30.avgTalkTime)}
          description={'Avg. talk time'}
          color="#009985"
        />
        <SubMetric
          value={displayTime(data.aht.ahtR30.avgHoldTime)}
          description={'Avg. hold time'}
          color="#A3ACB0"
        />
        <SubMetric
          value={displayTime(data.aht.ahtR30.avgAcwTime)}
          description={'Avg. ACW time'}
          color="#008ca4e2"
        />
      </Flex>
    </Flex>
  )
}

const AhtRightPanel = ({ data }: { data: AverageHandleTime }) => {
  const { t } = useTranslation()

  return (
    <Flex direction={'column'}>
      <Text style={{ marginBottom: space100 }}>{t('features.1-efficiency.aht-subtitle')}</Text>
      {data.ahtLast30IxnR90days.length > 0 ? (
        <Flex w={'100%'} h={300}>
          <AhtLineGraph data={data.ahtLast30IxnR90days} />
        </Flex>
      ) : (
        <Text>{t('No AHT trend data available')}</Text>
      )}
    </Flex>
  )
}

export default AhtSubPanel
