import Button from '@ingka/button'
import { LoadingBall } from '@ingka/loading'
import Select, { Option } from '@ingka/select'
import informationCircle from '@ingka/ssr-icon/paths/information-circle'
import Text from '@ingka/text'
import { space50, space100 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Department } from 'lib/types/coworkerData.types'

import { useCoworkerId } from 'hooks/id/useCoworkerId.hooks'

import { useCoworkerData } from 'state/slices/api'

import { colourGreyNeutral2 } from 'styles/tokens/insikt.tokens'

import { Widget } from 'components/composites/Shared/Widget'

import EfficiencyInfoModal from './InfoModal.component'
import AhtSubPanel from './SubPanels/AhtSubPanel.component'
import CsatSubPanel from './SubPanels/CsatSubPanel.component'
import RcSubPanel from './SubPanels/RcSubPanel.component'

const EfficencyPanel = () => {
  const [showModal, handleShowModal] = useDisclosure()
  const { t } = useTranslation()
  const coworkerId = useCoworkerId()
  const coworkerData = useCoworkerData(coworkerId).data
  const [selectedSkill, setSelectedSkill] = useState('')

  useEffect(() => {
    if (coworkerData) {
      setSelectedSkill(coworkerData.coworkerData.skillData[0].skill)
    }
  }, [coworkerData])

  if (!coworkerData) {
    return <LoadingBall />
  }

  const currentSkill = coworkerData.coworkerData.skillData.find(
    (skill) => skill.skill === selectedSkill
  )

  return (
    <Widget style={{ overflow: 'hidden', backgroundColor: colourGreyNeutral2 }}>
      <Flex direction={'column'} p={space100} gap={space100}>
        <Flex justify={'space-between'} align={'center'}>
          <Text headingSize="l">{t('features.1-efficiency.panel-title')}</Text>
          <Flex gap={space50}>
            <Button small ssrIcon={informationCircle} onClick={() => handleShowModal.open()}>
              {t('features.1-efficiency.info')}
            </Button>
          </Flex>
        </Flex>

        <Text style={{ maxWidth: 1000 }} bodySize="l">
          {t('features.1-efficiency.panel-description')}
        </Text>

        <CsatSubPanel data={coworkerData.coworkerData.csat} />

        <Flex align={'end'} gap={space50}>
          <Select
            style={{ minWidth: 200 }}
            label={'Skill'}
            id="test"
            value={selectedSkill}
            onChange={(e) => {
              if (e.target.value !== 'Choose an option') {
                setSelectedSkill(e.target.value)
              }
            }}
          >
            {coworkerData.coworkerData.skillData.map((skill, index) => {
              return (
                <Option
                  key={index}
                  name={`${index === 0 ? '⭐️ ' : ''} ${skill.skill} (${
                    skill.volumeR30
                  } interactions)`}
                  value={skill.skill}
                />
              )
            })}
          </Select>
        </Flex>

        {currentSkill?.department === Department.Resolution && (
          <>
            <AhtSubPanel data={currentSkill} />
            <RcSubPanel data={currentSkill} />
          </>
        )}
      </Flex>
      <EfficiencyInfoModal handleClose={handleShowModal.close} showModal={showModal} />
    </Widget>
  )
}

export default EfficencyPanel
