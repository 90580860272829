import SSRIcon from '@ingka/ssr-icon'
import warningTriangle from '@ingka/ssr-icon/paths/warning-triangle'
import Text from '@ingka/text'
import { space25, space100 } from '@ingka/variables'
import { Flex } from '@mantine/core'
import { useTranslation } from 'react-i18next'

import { RepeatContact, SkillData } from 'lib/types/coworkerData.types'

import { colourBrandYellow } from 'styles/tokens/oldSkapa.tokens'

import MainMetric from './components/MainMetric.component'
import RepeatContactGraph from 'components/composites/Graphs/repeatContactGraph.component'

import SubPanelLayout from './SubPanel.layout'

interface Props {
  data: SkillData
}

const RcSubPanel = ({ data }: Props) => {
  const { t } = useTranslation()

  if (!data.repeatContact) {
    return (
      <SubPanelLayout
        leftPanel={
          <Text bodySize="s" style={{ textAlign: 'center' }}>
            <SSRIcon paths={warningTriangle} style={{ marginRight: space25 }} />
            {t('No Repeat Contact data available')}
          </Text>
        }
        rightPanel={
          <Text bodySize="s" style={{ textAlign: 'center' }}>
            <SSRIcon paths={warningTriangle} style={{ marginRight: space25 }} />
            {t('No Repeat Contact data available')}
          </Text>
        }
      />
    )
  }

  return (
    <SubPanelLayout
      leftPanel={<RcLeftPanel data={data} />}
      rightPanel={<RcRightPanel data={data.repeatContact} />}
    />
  )
}

const RcLeftPanel = ({ data }: { data: SkillData }) => {
  const { t } = useTranslation()
  return (
    <Flex direction={'column'} gap={space100} w={'100%'} justify={'space-evenly'}>
      <Flex direction={'row'} justify={'space-between'} align={'center'}>
        <Text bodySize="s">{'Last 30 days'}</Text>
        {!data.repeatContact.repeatR30.enoughVolume && (
          <Text bodySize="s" style={{ textAlign: 'center' }}>
            <SSRIcon paths={warningTriangle} style={{ marginRight: space25 }} />
            {t('features.1-efficiency.low-data-volume')}
          </Text>
        )}
      </Flex>

      <Flex direction={'row'} justify={'space-between'} align={'center'}>
        <Text
          headingSize="m"
          style={{
            width: '100%',
          }}
        >
          {'Repeat contact'}
        </Text>

        <Text
          headingSize="s"
          style={{
            backgroundColor: colourBrandYellow,
            padding: space25,
            borderRadius: '5px',
            whiteSpace: 'nowrap',
            opacity: data.resolutionRecommendation?.recommendation === 'Repeat Contacts' ? 1 : 0,
          }}
        >
          {t('features.1-efficiency.highest-potential')}
        </Text>
      </Flex>

      <MainMetric
        value={data.repeatContact.repeatR30.repeatShare}
        avg={data.repeatContact.repeatR30.avgRepeatShare?.toFixed(1) ?? 'N/A'}
        top={data.repeatContact.repeatR30.minRepeatShare}
        unit="%"
        color="#FFA6DA"
      />
    </Flex>
  )
}

const RcRightPanel = ({ data }: { data: RepeatContact }) => {
  const { t } = useTranslation()

  return (
    <Flex direction={'column'}>
      {data.repeatLast30IxnR90days.length > 0 ? (
        <>
          <Text style={{ marginBottom: space100 }}>
            {t('features.1-efficiency.repeat-subtitle')}
          </Text>

          <Flex w={'100%'} h={300}>
            <RepeatContactGraph data={data.repeatLast30IxnR90days} />
          </Flex>
        </>
      ) : (
        <Text bodySize="s" style={{ textAlign: 'center' }}>
          <SSRIcon paths={warningTriangle} style={{ marginRight: space25 }} />
          {t('No Repeat Contact trend data available')}
        </Text>
      )}
    </Flex>
  )
}

export default RcSubPanel
