import Text from '@ingka/text'
import { space25, space50, space100 } from '@ingka/variables'
import { Flex } from '@mantine/core'

import {
  backgroundColourWhite,
  colourGreyNeutral3,
  colourGreyNeutral4,
} from 'styles/tokens/insikt.tokens'

interface Props {
  value: number | string
  unit: string
  avg: number | string
  top: number | string
  color?: string
}

const MainMetric = ({ value, unit, avg, top, color }: Props) => {
  return (
    <Flex
      direction={'row'}
      align={'center'}
      py={space100}
      px={space50}
      w={'100%'}
      gap={16}
      style={{
        borderRadius: 4,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: colourGreyNeutral4,
      }}
    >
      <Flex gap={16} align={'center'}>
        <div
          style={{ backgroundColor: color ? color : colourGreyNeutral3, height: 60, width: 16 }}
        />
        <Text style={{ whiteSpace: 'nowrap' }} headingSize="s">
          {value ? value + unit : 'N/A'}
        </Text>
      </Flex>

      <Flex direction={'row'} justify={'space-evenly'} align={'center'} w={'100%'}>
        <Flex direction={'column'} bg={backgroundColourWhite} p={space25} align={'center'}>
          <Text headingSize="xs">{avg ? avg + unit : 'N/A'}</Text>
          <Text bodySize="s">{'Country average'}</Text>
        </Flex>

        <Flex direction={'column'} bg={backgroundColourWhite} p={space25} align={'center'}>
          <Text headingSize="xs">{top ? top + unit : 'N/A'}</Text>
          <Text bodySize="s">{'Country best'}</Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default MainMetric
